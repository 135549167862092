import React, { useEffect } from "react"
import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Heading,
  Text,
  Width,
  CTA,
  ListItem,
  List,
  VideoPlayer,
} from "@/components"
import { color } from "@/theme/index"
import Img from "gatsby-image"
import styled from "styled-components"
import { breakpoints } from "@/theme/index"

export default function Application({ data }) {
  return (
    <Layout
      headerType="checkout"
      headerTheme="light"
      footerTheme="light"
      squeeze={true}
    >
      <SEO
        title="Father's Day Special"
        description="Sonora is running a very special Father's day deal this week only."
      />
      <Box
        bg="agate"
        height="100%"
        position="relative"
        zIndex="3"
        top="0"
        width="100%"
        display="flex"
        flexDirection="column"
        textAlign="center"
        pt={["7.5rem", null, null, "9.6rem"]}
        pb={["4.8rem", null, null, "0"]}
      >
        <Width
          display="flex"
          flexDirection="column"
          pt={["7.2rem", null, null, "2.2rem"]}
          pb={["0", null, null, "6.9rem"]}
        >
          <Heading
            mb="2rem"
            level="2"
            mt="3rem"
            pr={["0rem", "0rem", "2rem", "7rem"]}
          >
            <strong>Wanted:</strong> 30 Guitarists (MAX) Who Will Venture into
            New Territory with their Playing. Their reward?{" "}
            <strong>
              Full <strong>Fretboard Fluency</strong>, access to the Sonora
              training{" "}
            </strong>{" "}
            and <strong>all group sessions </strong>with $2,203 in savings.
          </Heading>
          <Box
            width={["100%", 12 / 12]}
            textAlign="left"
            mb={["2rem", null, null, "0rem"]}
            pr={["0", "0rem"]}
          >
            <VideoPlayer
              width="auto"
              maxWidth="auto"
              url="https://vimeo.com/835984539/710fe22048"
            />
          </Box>
          <Box
            width={["100%", 7 / 12]}
            mt="3rem"
            mb="1rem"
            mx="auto"
            textAlign="center"
          >
            <List bullets="checkmark">
              <ListItem color="tanzanite">
                <Text>
                  Brand new program - Sonora Core Lite™ launches today, and
                  closes doors on Sunday 06/18{" "}
                </Text>
              </ListItem>
              <ListItem color="tanzanite">
                <Text>
                  {" "}
                  Father’s Day and New Launch Special: RRP: $3,200 → Save 68% →
                  Just <strong>$997</strong> only
                </Text>
              </ListItem>
              <ListItem color="tanzanite">
                <Text>
                  {" "}
                  Watch the video above to see all extra details (inc 4
                  bonuses). Then use the 2 guarantees applied when you’re ready
                  to join
                </Text>
              </ListItem>
            </List>
          </Box>

          <Box mb={["2rem", null, null, "0"]}>
            <CTA
              variant="special"
              to="https://buy.stripe.com/5kAg0Z3rH7TmdDW14e"
              mb="1rem"
              mt="3rem"
              fontSize="2em"
            >
              <Text level="1">Join Sonora Core Lite™ (Save $2,203)</Text>
            </CTA>
          </Box>
        </Width>
        <Width>
          <Box
            width={["100%", null, null, 12 / 12]}
            textAlign="left"
            mx="auto"
            pr={["2rem", "2rem", "2rem", "3rem"]}
          >
            <Heading
              mb="1rem"
              mt="1rem"
              pr={["0rem", "0rem", "2rem", "7rem"]}
              level="2"
            >
              Dear Guitarists,
            </Heading>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              Allow me to paint the picture.
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              If you’ve ever wanted to see behind the scenes of how the full
              Sonora program works…
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              If you’ve ever wanted to jump on a session with our pro teachers
              and faculty…
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              If you’ve wanted to get our feedback and rapidly improve your
              playing in shorter time…
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              Then I want to invite you to something we’ve never, ever done
              before.
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              We’ve been asked for a version of this, but we’ve never created
              it. Until now! And for the first time, it’s finally here loaded
              with extras and a suite of bonuses inside.
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              For your savings, because it’s new, and because it’s Father’s Day
              this Sunday, this new program is actually 80% off the full Sonora
              mentorship investment.
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              It comes with $2,303 in savings, 4 bonuses, and two guarantees to
              arm your playing arsenal with.
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              As you see this - we are LIVE today - then we close on Fathers Day
              - Sunday 18th June (06/18). And it’s unlikely this will run again
              later in 2023. So this is truly the first time ever, and probably
              the last time this year!
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              Therefore read through this shorter page and judge for yourself
              whether you want to use the credit that’s been applied on this
              page.
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              All details are listed below, and if you do decide to join us,
              you’ll have immediate access starting Monday 06/19. And FYI…
            </Text>
            <Heading
              textAlign="center"
              mb="2rem"
              mt="3rem"
              pr={["0rem", "0rem", "2rem", "7rem"]}
              level="2"
            >
              Everything you’ll own from today - including lifetime program
              access - and 6 full months of live group training:
            </Heading>
            <Box mb="2rem">
              <List bullets="checkmark">
                <ListItem color="tanzanite">
                  <Text>
                    <strong>Our Ivy League level training program</strong> →
                    That features a built in learning path, on-demand material,
                    and the sequential path to intermediate and advanced
                    player/musician.
                  </Text>
                </ListItem>
                <ListItem color="tanzanite">
                  <Text>
                    <strong>2x group Q&A and live practice sessions</strong> →
                    That feature a pro teacher and member of the Sonora faculty.
                    You’ll be in a safe environment, with a small group of
                    peers, to grow and expand on the instrument, and any
                    question you have will be answered.
                  </Text>
                </ListItem>
                <ListItem color="tanzanite">
                  <Text>
                    <strong>Live extras masterclasses</strong> → With leading
                    musicians and guitarists like Greg Koch, Josh Smith, Josh
                    Sklair, and more.
                  </Text>
                </ListItem>
                <ListItem color="tanzanite">
                  <Text>
                    <strong>Song Study Class</strong> → We break down songs over
                    different genres and world music like flamenco, tango,
                    celtic, gypsy jazz, bossa nova, bluegrass, and more.
                  </Text>
                </ListItem>
              </List>
            </Box>
            <Text mb="2rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              And the accelerated training software alone features:
            </Text>
            <Box mb="2rem">
              <List bullets="checkmark">
                <ListItem color="tanzanite">
                  <Text>
                    <strong>Intelligent coaching</strong> → Which allows you to
                    absorb, polish, and retain core concepts through accelerated
                    learning and language acquisition techniques. This
                    transitions over to guitar perfectly, and allows you to
                    “chunk down” concepts that would usually take weeks of
                    repetition into days.
                  </Text>
                </ListItem>
                <ListItem color="tanzanite">
                  <Text>
                    <strong>Custom practice plans</strong> → So you’ll know
                    exactly what to work on every day without wasting a single
                    minute of practice time. Allows you to step up to the
                    instrument with a goal, make it happen, and repeat that as
                    you bust through plateaus.
                  </Text>
                </ListItem>
              </List>
            </Box>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              That means you can take a “teacher” with you anywhere, on-demand,
              24/7… with upgraded lessons when you need, technique
              masterclasses, and built in practice mechanics that stem from
              neuroscience. But even better…
            </Text>

            <Heading
              textAlign="center"
              mb="1rem"
              mt="3rem"
              pr={["0rem", "0rem", "2rem", "7rem"]}
              level="2"
            >
              It’s like taking a pro teacher with you anywhere you go… Only it’s
              cheaper & doesn’t come with ever increasing costs!
            </Heading>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              The average guitar teacher charges $50 an hour. You visit that
              teacher weekly and over:
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              3 months → You’ve paid $600 in teaching fees
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              6 months → You’ve paid $1,200 in teaching fees
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              9 months → You’ve paid $1,800 in teaching fees
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              That goes on until you stop, unfortunately. But what if it was
              different?
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              What if your training grew with you, advanced with you, and
              routinely gave you new ideas to practice, licks to run through,
              and stretched your playing daily.
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              And what if you had access to our live group calls, masterclasses,
              and song breakdowns for six full months on top of that.
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              Then what if it cost less than your average teacher, and advanced
              your results that much further.
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              Well, welcome inside Sonora Core Lite™.
            </Text>
            <Heading
              textAlign="center"
              mb="1rem"
              mt="3rem"
              pr={["0rem", "0rem", "2rem", "7rem"]}
              level="2"
            >
              Credit Active: Save $2,203 on this page and activate 6 months of
              Lite™ group access
            </Heading>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              Sonora Core Mentorship™ is our full tier, flagship program that
              involves 1-1 active mentorship with a specific pro teacher.
            </Text>
            <Text mb="2rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              It's an incredible program and the only one of its kind. It’s
              benefited more than 2,113 students as of writing this. However
              it's sometime financially inaccessible for some - as it’s 80% more
              than the newly developed Core Lite™. Core Lite™ packs much of the
              same feature set, but at a much more affordable price point.
              Because inside this lighter version, you’ll still own:
            </Text>
            <Box mb="2rem">
              <List bullets="checkmark">
                <ListItem color="tanzanite">
                  <Text>Our complete training and curriculum</Text>
                </ListItem>
                <ListItem color="tanzanite">
                  <Text>All back access to recorded coaching calls</Text>
                </ListItem>
                <ListItem color="tanzanite">
                  <Text>
                    The full laid out path to fretboard fluency with feedback
                  </Text>
                </ListItem>
                <ListItem color="tanzanite">
                  <Text>
                    Access to our weekly coaching programs from our team of
                    master teachers
                  </Text>
                </ListItem>
              </List>
            </Box>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              And you’ll do it with 80% savings over Core™, and $2,203 total
              saved if you decide to come inside. However this offer only
              extends until Father’s Day ends on June 18th (Sunday) at midnight.
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              And as a heads up, when Core Lite™ goes live again to the public,
              it won’t be at the price you see here.
            </Text>

            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              Because if I’m honest with you, the marketing team is a *little*
              uncomfortable with this offer being put together. And at the price
              I’m releasing and launching this at, I’m making a decision to
              provide this level of education to you.
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              So to be abundantly clear, Core Lite™ cannot stay at this price
              and that’s the agreement our team came to. This is a one and done.
            </Text>

            <Heading
              level="2"
              textAlign="center"
              mb="1rem"
              mt="3rem"
              pr={["0rem", "0rem", "2rem", "7rem"]}
            >
              When this goes live again in the future, and from here on out, it
              will be $3,200. But not today (save 68%)
            </Heading>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              What that means for you is not only the savings of $2,203 kept in
              your pocket, but also access to the ability to build fretboard
              fluency, learn the solos you want, master worldly rhythms, spruce
              up your chops, play licks that catch people’s attention, write your
              own songs, and improvise in a way that makes you smile.
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              To do it, you get full ride access to our Sonora curriculum,
              training lab, and practice plans, and you own that for life.
            </Text>

            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              So instead of our flagship coaching program, or the full price
              Core Lite™ investment, you can join today and use the launch and
              Father’s Day credit, save 68%, and join for just $997.
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              Write that off over a year, and access to the guitar training,
              path, and learning environment that’s guaranteed to help you
              rapidly advance is just $2.73 a day.
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              And once your 6 months are up inside our group program, you’ll
              have everything (and I mean everything) you need to continue your
              playing, practice regime, and continually progress.
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              So if you want to join me inside and access our group sessions,
              our full curriculum, and advanced your playing, click below now:
            </Text>
            <Width>
            <Box mb={["2rem", null, null, "0"]} textAlign="center">
              <CTA
                variant="special"
                to="https://buy.stripe.com/5kAg0Z3rH7TmdDW14e"
                mb="1rem"
                mt="3rem"
                fontSize="2em"
              >
                <Text level="1">Join Sonora Core Lite™ (Save $2,203)</Text>
              </CTA>
            </Box>
            </Width>
            <Heading
              level="2"
              textAlign="center"
              mb="1rem"
              mt="3rem"
              pr={["0rem", "0rem", "2rem", "7rem"]}
            >
              Plus 4 bonuses for our Launch and Father’s Day Offer!
            </Heading>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              <strong>1️⃣ Accelerated Learning Module</strong> → You’ll discover
              exactly how to make one hour of practice do the work of two. That
              then applies to everything you do with the instrument (and
              honestly the compound effects alone of this series is incredible,
              and applies beyond the instrument)
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              <strong>2️⃣ Dedicated Support Point of Contact</strong> → You’ll be
              onboarded 1-1 by a member of our team so you’re crystal clear on
              exactly what to do, where to go, and when to be there. They’ll
              also be a source of ongoing support for whatever you need on your
              six month journey with us.
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              <strong>3️⃣ Double Duration</strong> → Sonora Core™ is a 12 week
              container, and Core Lite™ would be no different. However because
              of our Launch and Father’s Day special, there’s an extra 3 months
              as your bonus. That brings your time with us to six full months,
              180 days, or 26 weeks.
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              <strong>4️⃣ (Extra) $200 credit towards 1-1 mentorship</strong> →
              We have members of staff who’ve won grammys, and you’d get 1-1
              access with the teacher we believe would best suit you IF you want
              it. As your fourth bonus, you’ll receive a $200 credit (which must
              be used over the first month inside Core Lite™). The choice is,
              and always will be, yours.
            </Text>
            <Heading
              level="2"
              textAlign="center"
              mb="1rem"
              mt="4rem"
              pr={["0rem", "0rem", "2rem", "7rem"]}
            >
              Take Full Advantage of Your *TWO* Guarantees: Core Lite™ Must Work
              For You as a Player
            </Heading>
            <Text mb="2rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              <strong>🎸 Guarantee 1 </strong>→ You have 30 full days to decide
            </Text>
            <Text mb="2rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              Truthfully, you either love it or leave it, and I’m giving you 30
              full days of your full group coaching time with us to decide.
            </Text>
            <Text mb="2rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              What this means is that in the unlikely event you feel Core Lite™
              is NOT going to move your playing ahead, you can back out.
            </Text>
            <Text mb="4rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              That’s amazing because it also allows you to decide later. So if
              you’re on the fence about something inside Lite™ or the Sonora
              process, you can judge it today and make your firm decision later.
            </Text>
            <Text mb="2rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              <strong>🎸 Guarantee 2 </strong>→ You can run through the WHOLE
              program with us for 6 months, and here’s what I’ll extend for the
              first ever launch of Lite™.
            </Text>
            <Text mb="2rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              Just go through at least 70% of our curriculum and training. Show
              up to just 70% of our weekly live classes over your six months
              with us (that’s 20 classes total)...
            </Text>
            <Text mb="2rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              And if your playing isn’t noticeably better by the end of your
              sprint with us, I will hand your full investment back to you.
            </Text>
            <Text mb="2rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              However what that really means is unless your time with us
              improves your playing, you won’t pay. That’s how much conviction I
              have in the systems and training we’ve created here, and I want
              you to be empowered that the decisions you make in your playing
              will work for you
            </Text>
          </Box>
        </Width>
        <Width>
          <Box mb="2rem">
            <Heading
              level="2"
              textAlign="center"
              mb="1rem"
              mt="1rem"
              pr={["0rem", "0rem", "2rem", "7rem"]}
            >
              All the details if you’re in a hurry
            </Heading>
            <List bullets="checkmark">
              <ListItem color="tanzanite">
                <Text>Our complete training and curriculum</Text>
              </ListItem>
              <ListItem color="tanzanite">
                <Text>All back access to recorded coaching calls</Text>
              </ListItem>
              <ListItem color="tanzanite">
                <Text>
                  The full laid out path to fretboard fluency with feedback
                </Text>
              </ListItem>
              <ListItem color="tanzanite">
                <Text>
                  Access to our weekly coaching programs from our team of master
                  teachers
                </Text>
              </ListItem>
            </List>
          </Box>
          <Text mb="4rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
            So when you’re ready to join, click the button below and I’ll see
            you on our first series of group coaching sessions next week!
          </Text>
          <Text mb="4rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
            Yours in practice and growth,
          </Text>
          <Text mb="4rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
            Spencer, Sonora CEO
          </Text>
        </Width>

        <Width
          display="flex"
          flexDirection={["column", "row"]}
          pt={["1.2rem", null, null, "5.2rem"]}
          pb={["0", null, null, "2.9rem"]}
        >
          <Box
            width={["100%", null, null, 6 / 12]}
            textAlign="left"
            mx="auto"
            pr={["2rem", "2rem", "2rem", "3rem"]}
          >
            <Heading level="xl" mb="1rem" mt={["3rem", "3rem", "3rem", "1rem"]}>
              Let's start your Guitar Journey
            </Heading>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              Sonora Core Lite™ is the <strong>fastest</strong> way to jumpstart
              your playing and finally understand the guitar.
            </Text>
            <Text
              level="2"
              mb={["5rem", "5rem", "5rem", "5rem"]}
              mt="3rem"
              pr={["0rem", "2rem", "2rem", "4rem"]}
            >
              Sonora's Intensive Core Program is designed to take participants
              stuck at the intermediate plateau on guitar to full creative
              fluency under the instruction of world-class mentors.
            </Text>
            <HideCard>
              <Box mb="3em">
                <CTA variant="special" to="#survey">
                  {" "}
                  Get Started
                </CTA>
              </Box>
            </HideCard>
            <Box>
              <Box
                width={["100%", null, 12 / 12, null]}
                display="flex"
                flexDirection="row"
                mt={["0rem", null, null, "3rem"]}
              >
                <Box width={[3 / 12, null]} pr="2rem">
                  <Box borderRadius="100%" overflow="hidden">
                    <Img
                      alt={"david"}
                      fluid={data.davidEImage.childImageSharp.fluid}
                    />
                  </Box>
                </Box>
                <Text
                  width={["100%", null, 9 / 12, null]}
                  level="2"
                  mb="1rem"
                  pr={["2rem", "2rem", "2rem", "4rem"]}
                >
                  "This is the most thoughtful, innovative, and intelligent
                  methodology for internalizing not only fretboard fluency, but
                  music in general." <br></br>
                  <strong>- David Engelhardt, Sonora Alumni</strong>
                </Text>
              </Box>
            </Box>
            <Box
              width={["100%", null, 12 / 12, null]}
              display="flex"
              flexDirection="row"
              mt="3rem"
            >
              <Box width={[3 / 12, null]} pr="2rem">
                <Box borderRadius="100%" overflow="hidden">
                  <Img
                    alt={"milton"}
                    fluid={data.miltonPImage.childImageSharp.fluid}
                  />
                </Box>
              </Box>
              <Text
                width={["100%", null, 9 / 12, null]}
                level="2"
                mb="1rem"
                pr={["2rem", "2rem", "2rem", "4rem"]}
              >
                "This guitar learning program is by far the best one ever
                conceptualized." <br></br>
                <strong>- Milton Pizarro, Sonora Alumni</strong>
              </Text>
            </Box>
            <Box
              width={["100%", null, 12 / 12, null]}
              display="flex"
              flexDirection="row"
              mt="3rem"
              mb="3rem"
            >
              <Box width={[3 / 12, null]} pr="2rem">
                <Box borderRadius="100%" overflow="hidden">
                  <Img
                    alt={"kevin"}
                    fluid={data.katieBImage.childImageSharp.fluid}
                  />
                </Box>
              </Box>
              <Text
                width={["100%", null, 9 / 12, null]}
                level="2"
                mb="1rem"
                pr={["2rem", "2rem", "2rem", "4rem"]}
              >
                "It was an incredibly worthwhile investment that helped me break
                out of a years long rut as a writer and player." <br></br>
                <strong>- Katie Boeck, Sonora Alumni</strong>
              </Text>
            </Box>
          </Box>

          <Box width={["90%", null, null, 6 / 12]} id="survey" mx="auto">
            <Box
              backgroundImage="linear-gradient(79.88deg, #5127AE 27.59%, #D04556 100.28%)"
              borderRadius=".8rem"
              width={["100%", "80%", 12 / 12, null]}
              mx="auto"
            >
              <Box
                width={"100%"}
                borderRadius=".6rem"
                textAlign="center"
                mb={["0rem", null, "0"]}
                bg={color.opal}
                ml={["auto", null, 0, null]}
                mr={["auto", null, "1.8rem", null]}
              >
                <Box
                  width={"100%"}
                  p="2.4rem"
                  borderRadius=".6rem"
                  textAlign="center"
                  mb={"0rem"}
                >
                  <Heading
                    mb=".8rem"
                    mx={["auto", 0, null, null]}
                    color={color.tanzanite}
                    textAlign="center"
                  >
                    Sonora Group Program
                  </Heading>
                  <Text
                    textAlign="center"
                    color={color.tanzanite}
                    fontSize="19px"
                    mb="1rem"
                  >
                    12-week Program for Intermediate Guitarists
                  </Text>
                  <Box textAlign="left">
                    <Text
                      fontSize="14px"
                      fontWeight="bold"
                      color={color.tanzanite}
                      mb="2rem"
                      mt="2rem"
                      textAlign="center"
                    >
                      INCLUDED
                    </Text>
                    <List bullets="checkmark">
                      <ListItem color="tanzanite">
                        Lifetime Access to the Curriculum
                      </ListItem>
                      <ListItem color="tanzanite">
                        Lifetime Access to our Accelerated Learning Software
                      </ListItem>
                      <ListItem color="tanzanite">
                        Unlimited Access to Our Team and Community
                      </ListItem>
                      <ListItem color="tanzanite">
                        3 Months of Live Classes - 4x weekly live classes
                      </ListItem>
                      {/* <ListItem color="tanzanite">
                        {" "}
                        $500 Tuition Credit for Sonora Guitar Intensive
                        Intermediate Program
                      </ListItem> */}
                    </List>
                  </Box>

                  <Box textAlign="left">
                    <Text
                      fontSize="14px"
                      fontWeight="bold"
                      color={color.tanzanite}
                      mb="2rem"
                      mt="2rem"
                      textAlign="center"
                    >
                      BONUSES
                    </Text>
                    <List bullets="checkmark">
                      <ListItem color="tanzanite">
                        BONUS #1 - Accelerated Learning Module
                      </ListItem>
                      <ListItem color="tanzanite">
                        BONUS #2 - A Dedicated Support Person
                      </ListItem>
                      <ListItem color="tanzanite">
                        BONUS #3 - 3 Extra Months of Group Class Access
                      </ListItem>
                      <ListItem color="tanzanite">
                        BONUS #4 - $200 Credit Toward a 6-Month Mentorship
                      </ListItem>
                      {/* <ListItem color="tanzanite">
                        {" "}
                        $500 Tuition Credit for Sonora Guitar Intensive
                        Intermediate Program
                      </ListItem> */}
                    </List>
                  </Box>
                </Box>
                <Box
                  backgroundImage="linear-gradient(72.5deg, #651FFF 0%, #D04556 99.51%)"
                  color="white"
                  py="2rem"
                  borderBottomRightRadius=".6rem"
                  borderBottomLeftRadius=".6rem"
                >
                  <>
                    <Box
                      display="flex"
                      mb="0.6rem"
                      alignItems="center"
                      justifyContent={["center", null]}
                    >
                      <h4>
                        <SmallNumber>$3200</SmallNumber>
                      </h4>
                    </Box>
                    <Text
                      fontSize="14px"
                      fontWeight="bold"
                      color={color.white}
                      mb=".6rem"
                      textAlign="center"
                    >
                      30 SEATS AVAILABLE
                    </Text>
                  </>
                  <Box
                    display="flex"
                    mb="0.6rem"
                    alignItems="center"
                    justifyContent={["center", null]}
                  >
                    <h4>
                      <Dollar>$ </Dollar>
                      <Number>997</Number>{" "}
                    </h4>
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* <ApplicationCard>
              <Heading level="2" mb={["5rem", null, null, "2rem"]}>
                Tell us about yourself
              </Heading>
              <div id="hubspotForm"></div>
            </ApplicationCard> */}
          </Box>
        </Width>
        <Box mb={["2rem", null, null, "0"]}>
          <CTA
            variant="special"
            to="https://buy.stripe.com/5kAg0Z3rH7TmdDW14e"
            mb="1rem"
            mt="3rem"
            fontSize="2em"
          >
            <Text level="1">Join Sonora Core Lite™ (Save $2,203)</Text>
          </CTA>
        </Box>
      </Box>
    </Layout>
  )
}

const ApplicationCard = styled.div`
  background-color: white;
  border-radius: 0.5em;
  padding: 1em;
`
const HideCard = styled.div`
  @media (min-width: ${breakpoints[1]}) {
    display: none;
  }
`

/* Image and Other GQL Queries */
export const applicationQuery = graphql`
  query {
    davidEImage: file(relativePath: { eq: "davide.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    kevinSImage: file(relativePath: { eq: "kevins.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    miltonPImage: file(relativePath: { eq: "miltonp.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    katieBImage: file(relativePath: { eq: "katieb.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    erikLImage: file(relativePath: { eq: "erikl.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

const Number = styled.span`
  font-size: 4.2rem;
  line-height: 1.16;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
`

const Dollar = styled.span`
  font-weight: 300;
  font-size: 3.4rem;
  line-height: 1.16;
  letter-spacing: 0.01em;
  color: rgba(179, 168, 203, 0.5);
`

const SmallNumber = styled.span`
  font-size: 2.2rem;
  text-decoration: line-through;
  line-height: 1.16;
  opacity: 0.5;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
`
